.aboutme {
    background-color: #112b51;
    color: #fff;
    margin: 2% 15%;
    padding: 1em;
}


.center-word {
     text-align: center;
     font-family: "Apple Chancery";
     font-size: 2rem;
 }
.center-word-small {
    text-align: center;
    font-family: "Apple Chancery";
    font-size: 1rem;
}
.images {
    margin-bottom: 1.5em;
    zoom: 0.8;
}
